import { makeStyles } from "@material-ui/core";
import sloganBackgroundImage from "../../images/slogan-background.png"

const useStyles = makeStyles(
    (theme) => (
        {
            slogan : {
                backgroundImage : 'url("' + sloganBackgroundImage + '")',
                backgroundPosition : 'center',
                backgroundRepeat : 'no-repeat',
                backgroundSize : 'cover',
                height : '50vh',
            },
            sloganTextContainer : {
                background : 'rgba(196, 196, 196, 0.36)',
                height : '50%',
                display : 'flex',
                justifyContent : 'center',
                alignItems : 'center',
                color : 'white',
                fontFamily : 'Roboto',
                fontSize : 70,
                [theme.breakpoints.down("961")]: {
                    fontSize : 50,
                },
                [theme.breakpoints.down("692")]: {
                    fontSize : '7vw',
                },
            }
        }
    )
)

const Slogan = () => {
    const classes = useStyles();
    return (
        <div className={classes.slogan}>
            <div className={classes.sloganTextContainer}>Ήρθε η ώρα σας να λάμψετε</div>
        </div>
    )
}

export default Slogan;