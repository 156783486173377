import { makeStyles } from "@material-ui/core";
import CallButton from './CallButon.jsx'

const useStyles = makeStyles(
    (theme) => (
        {
            container : {
                display : 'flex',
                flexDirection : 'column',
                justifyContent : 'space-between',
                alignItems : 'center',
                background : '#2f2f2f',
                height : 200,
            },
            title : {
                color : 'white',
                fontFamily : 'Roboto',
                width : '100%',
                backgroundColor : '#505050',
                display : 'flex',
                justifyContent : 'center',
                alignItems : 'center',
                minHeight : '70px',
                fontSize : 25,
                [theme.breakpoints.down("840")]: {
                    fontSize : 20,
                    padding : 5,
                    display : 'block',
                    textAlign : 'center',
                }
            }
        }
    )
)

function CallSection() {
    const classes = useStyles();
    return ( 
        <div className={classes.container}>
            <div className={classes.title}>
                Μπορείτε να έρθετε σε επικοινωνία μαζί μας πατώντας το παρακάτω κουμπί
            </div>
            <CallButton />
        </div>
     );
}

export default CallSection;