import { makeStyles } from "@material-ui/core";
import GoogleMaps from "./GoogleMaps";
import CallSection from './CallSection.jsx'

const useStyles = makeStyles(
    () => (
        {
            container : {
                backgroundColor : '#D99791',
                minHeight : '700px',
                position: 'relative'
            }
        }
    )
)

const Contact = () => {
    const classes = useStyles();
    return (
        <div className={classes.container} id="Contact">
            <GoogleMaps />
            <CallSection />
        </div>
    )
}

export default Contact;