import { makeStyles } from "@material-ui/core";
import { Typography } from "@mui/material";

const useStyles = makeStyles(
    () => (
        {
            container : {
                display : 'inline-block',
                color : 'white',
            },
            line : {
                borderTop : '3px solid #D99791',
            },
        }
    )
)

const TimeTable = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <Typography>{props.date}</Typography>
            <hr className={classes.line}/>
            <Typography>{props.time}</Typography>
        </div>
    )
}

export default TimeTable;