import NavBar from "./NavBar"
import HeaderImage from "./HeaderImage";
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => (
        {
            Header : {
                height : '100vh',
            },
        }
    )
)


const Header = () =>
{
    const classes = useStyles();
    return(
        <div className={classes.Header} id="Header">
            <NavBar/>
            <HeaderImage />
        </div>
        
    )
}

export default Header;