import { makeStyles } from '@material-ui/core';
//import zIndex from '@mui/material/styles/zIndex';

const useStyles = makeStyles(() => (
    {
        textContainer : {
            background : 'rgba(196, 196, 196, 0.4)',
            padding : '15px',
            borderRadius : ' 0px 50px 0px 0px',
            color : 'white',
            fontFamily : 'Roboto',
            marginTop : '30px',
            position : 'relative',
            zIndex : '1',
            '&::before' : {
                content: 'asas',
                position: 'absolute',
                left: '0px',
                top: '0px',
                width: '50px',
                height: '50px',
                backgroundColor : 'red',
            }
        },
        bottomRow : {
            height : '30px',
            backgroundColor : '#7F5B58',
            position : 'absolute',
            bottom : '-18px',
            width : '93%',
            zIndex : '0',
            filter : 'blur(4px)',
        }
    }
)
)

const TextContainer = (props) => {
    const classes = useStyles();
    return(
        <div className={classes.textContainer}>
            {props.text}
            <div className={classes.bottomRow}></div>
        </div>
    )
}

export default TextContainer;