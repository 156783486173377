import { makeStyles } from '@material-ui/core';
import { Grid } from '@mui/material';
import degreeAtributeIcon from '../../images/experience1.png';
import haircutAttributeIcon from '../../images/hairdresser1.png';
import pleasureAttributeIcon from '../../images/massage1.png';
import { Typography } from '@mui/material';

const useStyles = makeStyles(
    (theme) => (
        {
            container : {
                width : '100%',
                backgroundColor : 'black',
                padding : 20,
            },
            attributeGridItem : {
                textAlign : 'center',
                paddingBottom : '100',
                alignItems : 'center',
                [theme.breakpoints.down("900")]: {
                    paddingBottom : 100,
                }
            },
            attributeGridImage : {
                marginBottom : 10,
            },
            attributeText : {
                color : '#fff',
            }
        }
    )
)

const Attributes = () => {
    const classes = useStyles();
    return (
        <Grid container className={classes.container}>
            <Grid item md={1} xs={0}/>
            <Grid item container md={10} xs={12}>
                <Grid item md={4} xs={12} className={classes.attributeGridItem}>
                    <img src={degreeAtributeIcon} alt="" className={classes.attributeGridImage}/>
                    <Typography className={classes.attributeText}>
                        ΕΜΠΕΙΡΙΑ ΑΠΟ ΤΟ 2006
                    </Typography>
                </Grid>
                <Grid item md={4} xs={12} className={classes.attributeGridItem}>
                    <img src={haircutAttributeIcon} alt="" className={classes.attributeGridImage}/>
                    <Typography className={classes.attributeText}>
                        ΠΑΝΩ ΑΠΟ 1000 ΚΟΥΡΕΜΑΤΑ
                    </Typography>
                </Grid>
                <Grid item md={4} xs={12} className={classes.attributeGridItem}>
                    <img src={pleasureAttributeIcon} alt="" className={classes.attributeGridImage}/>
                    <Typography className={classes.attributeText}>
                        200+ ΕΥΧΑΡΙΣΤΙΜΕΝΟΥΣ ΠΕΛΑΤΕΣ
                    </Typography>
                </Grid>
            </Grid>
            <Grid item md={1} xs={0} />
        </Grid>
    )
}

export default Attributes;