import Grid from '@mui/material/Grid';
import aboutUsImage from '../../images/aboutUsImage.png';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core';
import Container from '@mui/material/Container';

import TextContainer from './TextContainer';

const useStyles = makeStyles((theme) => (
    {
        ContainerGird : {
            backgroundColor : '#2F2F2F',
            paddingTop : '100px',
            paddingBottom : '100px',
        },
        image : {
            width : '370px',
            [theme.breakpoints.down("370")]: {
                width : '90%'
            }
        },
        title : {
            textAlign  : 'center'
        }
    }
)
)

const AboutUs = () => {
    const classes = useStyles();
    return (
        <Grid container className={classes.ContainerGird}>
            <Grid item lg={2} md={0} sm={0} xs={0}/> 
            <Grid item lg={4} md={6} sm={12} xs={12} style={{textAlign : 'center'}}>
                <img src={aboutUsImage} alt="Anna & Violetta" className={classes.image} />
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12} >
                <div>
                    <Typography variant="h4" color="white" className={classes.title}>
                        ΓΝΩΡΙΣΤΕ ΜΑΣ ΚΑΛΥΤΕΡΑ
                    </Typography>
                    <Container>
                                    <TextContainer text="Καλώς ορίσατε στην ιστοσελίδα μας. Είμαστε η Άννα και η Violetta, 
                            δύο πτυχιούχες κομμώτριες με πολλά χρόνια εμπειρίας και μαζί έχουμε το κομμωτήριο ANNA & VIOLETTA HAIRSTYLING. 
                            Συνοπτικά οι υπηρεσίες που προσφέρει το κομμωτηριο μας είναι : Όλων των ειδών κουρέματα, βαφές, extensions, θεραπείες, κερατίνη, 
                            νυφικό χτένισμα - μακιγιάζ και πολλά άλλα. Το κομμωτηριο 
                            μας βρίσκεται στην  Περαία Θεσσαλονίκης, πιο συγκεκριμένα στην οδό Δημοκρατίας 102. Το δυνατό μας χαρακτηριστικό είναι το πάθος που βάζουμε για κάθε υπηρεσία που προσφέρουμε. το κομμωτήριο λειτουργεί 
                            όλες τις μέρες της εβδομάδας εκτός κυριακής. Για κρατήσεις μπορείτε να μας τηλεφωνήσετε στον αριθμό 6988803266 ή 2392022860.
                            Θα χαρούμε πολύ να σας δούμε στο κατάστημα μας." />
                    </Container>
                   
                </div>
            </Grid>
            <Grid item lg={2} md={0} sm={0} xs={0}/>
        </Grid>
    )
}

export default AboutUs;