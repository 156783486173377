import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { makeStyles } from '@material-ui/core';

import logo from '../../images/logo.png';
//import { useRef } from 'react';

const useStyles = makeStyles((theme) => ({
  
    toolBarStyled: {
        backgroundColor : 'black',
        justifyContent : 'space-between',
        [theme.breakpoints.down("633")]: {
            flexDirection : "column"
          }},
    buttonNavBar : {
        textDecoration : 'none',
        color : 'white',
    }
}));

const NavBar = () =>
{
    const classes = useStyles();

    return(
        <AppBar>
            <Toolbar className={classes.toolBarStyled}>
                <img src={logo} alt="Anna & Violetta hairstyling logo" />
                <div className="buttons-group">
                    <a href="#Header" className={classes.buttonNavBar}><Button color="inherit" size="small">ΑΡΧΙΚΗ</Button></a>
                    <a href="#PriceList" className={classes.buttonNavBar}><Button color="inherit" size="small">ΥΠΗΡΕΣΙΕΣ</Button></a>
                    <a href="#TimeSection" className={classes.buttonNavBar}><Button color="inherit" size="small">ΩΡΕΣ ΛΕΙΤΟΥΡΓΙΑΣ</Button></a>
                    <a href="#Contact" className={classes.buttonNavBar}><Button color="inherit" size="small">ΕΠΙΚΟΙΝΩΝΙΑ</Button></a>
                </div>
            </Toolbar>
        </AppBar>
    )
}

export default NavBar;