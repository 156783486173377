import { makeStyles } from "@material-ui/core";
import TimeTable from "./TimeTable";
import { Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(
    () => (
        {
            container : {
                backgroundColor : '#2F2F2F',
                padding : 100,
            }
        }
    )
)

const TimeSection = () => {
    const classes = useStyles();
    return (
        <div className={classes.container} id="TimeSection">
            <Grid container>
                <Grid item md={2} xs={0}/>
                <Grid item md={4} xs={12}> <Typography variant='h4' style={{textAlign : 'center', color : 'white',paddingBottom : 50}}>ΩΡΕΣ  ΛΕΙΤΟΥΡΓΙΑΣ</Typography> </Grid>
                <Grid item container md={4} xs={12} spacing={2}>
                    <Grid item xs={6}><TimeTable date="ΔΕΥΤΕΡΑ" time="17:00 - 19:00" /></Grid>
                    <Grid item xs={6}><TimeTable date="ΚΑΘΗΜΕΡΙΝΕΣ" time="9:30 - 14:00 17:00 - 19:00" /></Grid>
                    <Grid item xs={6}><TimeTable date="ΣΑΒΒΑΤΟ" time="9:30 - 16:00" /></Grid>
                    <Grid item xs={6}><TimeTable date="ΚΥΡΙΑΚΗ" time="ΚΛΕΙΣΤΑ" /></Grid>
                </Grid>
                <Grid item md={2} xs={0}/>
            </Grid>
            
        </div>
       
    )
}

export default TimeSection;