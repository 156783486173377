import Header from './sections/header/Header.jsx'
import AboutUs from './sections/aboutUs/AboutUs.jsx';
import Attributes from './sections/attributes/Attributes.jsx';
import PriceList from './sections/PriceList/PriceList.jsx';
import Slogan from './sections/slogan/Slogan.jsx';
import TimeSection from './sections/timeTable/TimeSection.jsx';
import Gallery from './sections/Gallery/Gallery.jsx';
import Contact from './sections/contact/Contact.jsx';
import MyFooter from './sections/Footer/MyFooter.jsx';

const App = () => 
{
    return (
        <div>
            <Header />
            <AboutUs />
            <Attributes />
            <PriceList />
            <Slogan />
            <TimeSection />
            <Gallery />
            <Contact />
            <MyFooter />
        </div>
    )
}

export default App;