import CardPriceList from "./CardPriceList";
import { Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import Divider from '@mui/material/Divider';
import {useState, useEffect} from 'react';

/*

const PriceListCards = {
    data : [
        {
            title : 'Κουρέματα',
            servicesPrices : [
                {
                    serviceName : 'Γυναικείο',
                    servicePrice : 'Από 10€'
                },
                {
                    serviceName : 'Ανδρικό',
                    servicePrice : 'Από 10€'
                },
                {
                    serviceName : 'Παιδικό',
                    servicePrice : 'Από 7€'
                }
            ]
        },
        {
            title : 'Χτένισμα',
            servicesPrices : [
                {
                    serviceName : 'Βραδυνό',
                    servicePrice : 'Από 20€'
                },
                {
                    serviceName : 'Νυφικό χτένισμα',
                    servicePrice : 'Από 100€'
                },
                {
                    serviceName : 'Κοντό',
                    servicePrice : 'Από 8€'
                },
                {
                    serviceName : 'Μακρύ',
                    servicePrice : 'Από 10€'
                },
                {
                    serviceName : 'Πρέσσα/Σίδερο',
                    servicePrice : 'Από 15€'
                }
            ]
        },
        {
            title : 'Βαφές',
            servicesPrices : [
                {
                    serviceName : 'Βαφή',
                    servicePrice : 'Από 18€'
                },
                {
                    serviceName : 'Αντρική Βαφή',
                    servicePrice : 'Από 10€'
                },
                {
                    serviceName : 'Κερατίνη',
                    servicePrice : 'Από 35€'
                },
                {
                    serviceName : 'Permanant',
                    servicePrice : 'Από 35€'
                }
            ]
        },
        {
            title : 'Υπιρεσίες Προσώπου',
            servicesPrices : [
                {
                    serviceName : 'Μακιγιάζ',
                    servicePrice : 'Από 15€'
                },
                {
                    serviceName : 'Νυφικό Μακιγιάζ',
                    servicePrice : 'Από 100€'
                },
                {
                    serviceName : 'Ημιμόνιμο Μακιγιάζ φρυδιών',
                    servicePrice : 'Από 100€'
                },
                {
                    serviceName : 'αποτριχωση με κλωστη προσωπου',
                    servicePrice : 'Από 3€'
                }
            ]
        }
    ]
} 
*/

const PriceList = () => {
    
    const [PriceListCards,setData]=useState({
        data :[]
    });

    const getData=()=>{
        fetch('generated.json'
        ,{
          headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }
        }
        )
          .then(function(response){
            console.log(response)
            return response.json();
          })
          .then(function(myJson) {
            console.log(myJson.data[0].title);
            setData(myJson)
          });
      }

      useEffect(()=>{
        getData()
      },[])
      

    return (
        <div style={{backgroundColor : '#D99791', paddingBottom : 100, width : '100%'}} id="PriceList">
            <Grid container>
                <Grid item md={2} sm={0}/>
                <Grid item container md={8} sm={12} spacing={2}>
                    <Grid item xs={12} style={{marginBottom : 50, marginTop : 50}}>
                        <Divider><Typography variant="h3" style={{textAlign : 'center'}}>Τιμοκατάλογος</Typography></Divider>
                    </Grid>
                    
                    {PriceListCards.data.map(
                            function (cardData,index) {
                                if(index % 2 === 0)
                                    return ( <Grid item sm={6} xs={12}> <CardPriceList left={true} cardData={cardData}/></Grid>)
                                else
                                return ( <Grid item sm={6} xs={12}> <CardPriceList right={true} cardData={cardData}/></Grid>)
                                }
                            )}
                    
                </Grid>
                <Grid item md={2} sm={0}/>
            </Grid>
        </div>
    )
}




export default PriceList;