import { makeStyles } from '@material-ui/core';
import headerBackgroundImage from '../../images/Header.png'
import headerBackgroundImageMobile from '../../images/HeaderMobile2.png'
import headerBackgroundImageIpad from '../../images/HeaderIpad2.png'

const useStyles = makeStyles(
    (theme) => (
        {
            HeaderImage : {
                backgroundImage : 'url("' + headerBackgroundImage + '")',
                width : '100%',
                height : '100%',
                backgroundSize : 'cover',
                backgroundPosition : 'center',
                [theme.breakpoints.down("1418")]: {
                    backgroundImage : 'url("' + headerBackgroundImageIpad + '")',
                },
                [theme.breakpoints.down("768")]: {
                    backgroundImage : 'url("' + headerBackgroundImageMobile + '")',
                }
            }
        }
    )
)

const HeaderImage = () => {
    const classes = useStyles();
    return (
        <div className={classes.HeaderImage} />           
    )
}

export default HeaderImage;