import HeaderBackgroundImage from '../../images/HeaderBackgroundImage.png';
import HeaderBackgroundImageMobile from '../../images/HeaderBackgroundImageMobile.png';
import FacebookIcon from '../../images/facebook.png';
import InstagramIcon from '../../images/instagram.png';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles(
    (theme) => (
        {
            container : {
                backgroundImage : 'url("' + HeaderBackgroundImage + '")',
                height : 500,
                width : '100%',
                backgroundSize : 'cover',
                backgroundPosition : 'center',
                flexDirection : 'column',
                display : 'flex',
                justifyContent : 'center',
                alignItems : 'center',
                [theme.breakpoints.down("600")]:{
                    backgroundImage : 'url("' + HeaderBackgroundImageMobile + '")',
                    fontSize : '13px'
                }

            },
            navBar : {
                display : 'flex',
                flexWrap : 'wrap'
            },
            navigationButton : {
                color : 'white',
                fontFamily : 'Roboto',
                fontStyle : 'normal',
                padding : 20,
                cursor : 'pointer',
                [theme.breakpoints.down("600")]: {
                    padding : 5
                }
            },
            socialIcon : {
                padding : 10,
                cursor : 'pointer',
            },
            FooterNavItem : {
                color : 'white',
                textDecoration : 'none',
            }
        }
    )
)

const MyFooter = () => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.navBar}>
                <a href="#Header" className={classes.FooterNavItem}><div className={classes.navigationButton}>ARXIKH</div></a>
                <a href="#PriceList" className={classes.FooterNavItem}><div className={classes.navigationButton}>ΥΠΗΡΕΣΙΕΣ</div></a>
                <a href="#TimeSection" className={classes.FooterNavItem}><div className={classes.navigationButton}>ΩΡΕΣ ΛΕΙΤΟΥΡΓΙΑΣ</div></a>
                <a href="#Contact" className={classes.FooterNavItem}><div className={classes.navigationButton}>ΕΠΙΚΟΙΝΩΝΙΑ</div></a>
            </div>
            <div>
                <a href="https://www.facebook.com/profile.php?id=100073898337285"><img src={FacebookIcon} alt="" className={classes.socialIcon} /></a>
                <a href="https://www.instagram.com/annaviolettahairstyling/"><img src={InstagramIcon} alt="" className={classes.socialIcon} /></a>
            </div>
        </div>
    )
}

export default MyFooter;