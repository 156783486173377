import { makeStyles } from "@material-ui/core";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { Container } from "@material-ui/core";

const useStyles = makeStyles(
    (theme) => (
        {
            container : {
                
            },
            titleLeft : {
                textAlign : 'center',
                background : 'rgba(127, 91, 88, 0.8)',
                borderRadius : '30px 0px 0px 0px',
                color : 'white',
                padding : '7px',
                [theme.breakpoints.down("600")]: {
                    borderRadius : '30px 30px 0px 0px',
                }
            },
            servicesPricesLeft : {
                background : 'rgba(127, 91, 88, 0.46)',
                borderRadius : '0px 0px 0px 30px',
                color : 'white',
                marginTop : 10,
                [theme.breakpoints.down("600")]: {
                    borderRadius : '0px 0px 30px 30px',
                }
            },
            titleRight : {
                textAlign : 'center',
                background : 'rgba(127, 91, 88, 0.8)',
                borderRadius : '0px 30px 0px 0px',
                color : 'white',
                padding : '7px',
                [theme.breakpoints.down("600")]: {
                    borderRadius : '30px 30px 0px 0px',
                }
            },
            servicesPricesRight : {
                background : 'rgba(127, 91, 88, 0.46)',
                borderRadius : '0px 0px 30px 0px',
                color : 'white',
                marginTop : 10,
                [theme.breakpoints.down("600")]: {
                    borderRadius : '0px 0px 30px 30px',
                }
            },
            
        }
    )
)

const CardPriceList = (props) => {
    const classes = useStyles();
    return(
        <Container>
            {
                props.left && <div>
                    <Typography className={classes.titleLeft} variant="h5">{props.cardData.title}</Typography>
            <Grid container className={classes.servicesPricesLeft}>
                {props.cardData.servicesPrices.map(
                    (servicesPriceRow) => (
                        <Grid item container style={{padding : 5}} alignItems='center'>
                            <Grid item xs={4}><Typography variant="subtitle1" style={{textAlign : 'center'}}>{servicesPriceRow.serviceName}</Typography></Grid>
                            <Grid item xs={4}><hr /></Grid>
                            <Grid item xs={4}><Typography variant="subtitle1" style={{textAlign : 'center'}}>{servicesPriceRow.servicePrice}</Typography></Grid>
                        </Grid>
                    )
                )}
            </Grid>
                </div>
            }
            {
                props.right && <div>
                    <Typography className={classes.titleRight} variant="h5">{props.cardData.title}</Typography>
            <Grid container className={classes.servicesPricesRight}>
                {props.cardData.servicesPrices.map(
                    (servicesPriceRow) => (
                        <Grid item container style={{padding : 5}}>
                            <Grid item xs={4}><Typography variant="subtitle1" style={{textAlign : 'center'}}>{servicesPriceRow.serviceName}</Typography></Grid>
                            <Grid item xs={4}><hr /></Grid>
                            <Grid item xs={4}><Typography variant="subtitle1" style={{textAlign : 'center'}}>{servicesPriceRow.servicePrice}</Typography></Grid>
                        </Grid>
                    )
                )}
            </Grid>
                </div>
            }
        </Container>
    )
}

export default CardPriceList;