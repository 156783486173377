import { makeStyles } from "@material-ui/core";
import phoneCallImage from '../../images/phoneCall.png'

const useStyles = makeStyles(
    (theme) => (
        {
            button : {
                background : '#D99791',
                border : '7px solid #916763',
                boxShadow : '0px 4px 4px rgba(0,0,0,0.25)',
                borderRadius : '50px',
                height : '50px',
                maxWidth : 300,
                display : 'flex',
                justifyContent : 'center',
                alignItems : 'center',
                fontFamily : 'Roboto',
                color : 'white',
                fontSize : 23,
                cursor : 'pointer',
                padding : '0px 10px 0px 10px',
                marginBottom : 25,
                textAlign : 'center'
            }
        }
    )
)

function CallButton() {
    const classes = useStyles();
    return ( 
        <a href="tel:+302392022860" style={{textDecoration : 'none'}}><div className={classes.button}>23920 22860 <img src={phoneCallImage} alt="call button" style={{height : 40, marginLeft : 20}}/> </div></a>
     );
}

export default CallButton;