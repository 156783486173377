import Image1 from '../../images/000.png';
import Image2 from '../../images/001.png';
import Image3 from '../../images/002.png';
import Image4 from '../../images/003.png';
import Image5 from '../../images/004.png';
import Image6 from '../../images/005.png';
import Image7 from '../../images/006.png';
import Image8 from '../../images/007.png';
import Image9 from '../../images/008.png';
import Image10 from '../../images/009.png';
import Image11 from '../../images/010.png';
import './gallery.css'
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

import OurPlaceLabel from './OurPlaceLabel';


const itemData = [
    {
        img :  Image1,
        title: 'Anna & Violetta',
    },
    {
        img :  Image2,
        title: 'Anna & Violetta',
    },
    {
        img :  Image3,
        title: 'Anna & Violetta',
    },
    {
        img :  Image4,
        title: 'Anna & Violetta',
    },
    {
        img :  Image5,
        title: 'Anna & Violetta',
    },
    {
        img :  Image6,
        title: 'Anna & Violetta',
    },
    {
        img :  Image7,
        title: 'Anna & Violetta',
    },
    {
        img :  Image8,
        title: 'Anna & Violetta',
    },
    {
        img :  Image9,
        title: 'Anna & Violetta',
    },
    {
        img :  Image10,
        title: 'Anna & Violetta',
    },
    {
        img :  Image11,
        title: 'Anna & Violetta',
    },
]



const Gallery = () => {
    const [model , setModel] = useState(false);
    const [tempimgSrc , setTempimgSrc] = useState('');

    function getImg(imgSrc)
    {
        setTempimgSrc(imgSrc);
        setModel(true);
    }

    return (
        <div style={{position : 'relative'}}>
            <div className={model ? "model open" : "model"}>
                <img src={tempimgSrc} />
                <CloseIcon onClick={() => {setModel(false)}}/>
            </div>
            <OurPlaceLabel />
            <div className="gallery">
                {itemData.map(
                    (item,index) => {
                        return (
                            <div className="pics" key={index} onClick={() => getImg(item.img)}>
                                <img src={item.img} alt="Anna & Violetta" style={{width : '100%'}}/>
                            </div>
                        )
                    }
                )}
            </div>
        </div>
    )
}

export default Gallery;