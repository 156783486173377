import { makeStyles } from '@material-ui/core';
import { Typography } from '@mui/material';
import { fontSize } from '@mui/system';

const useStyles = makeStyles(
    () => (
        {
            container : {
                background : '#2F2F2F',
                border : ' 2px solid #D99791',
                display : 'inline-block',
                color : 'white',
                padding : 10,
                position : 'absolute',
                top : '-28px',
                left : '0',
                right : '0',
                marginLeft : 'auto',
                marginRight : 'auto',
                width : "200px",
                textAlign : 'center',
            }
        }
    )
)

const OurPlaceLabel = () => {
    const classes = useStyles();
    return (
        <div className={classes.container}><Typography variant='h5'>Ο ΧΩΡΟΣ ΜΑΣ</Typography></div>
    )
}

export default OurPlaceLabel;